function openModal(id) {
    //document.getElementById("backdrop").style.display = "block"
    document.getElementById(id).style.display = "block"
    document.getElementById(id).classList.add("show fade")
}
function closeModal(id) {
    //document.getElementById("backdrop").style.display = "none"
    document.getElementById(id).style.display = "none"
    document.getElementById(id).classList.remove("show fade")
}
