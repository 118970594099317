$(function () {
    var text = $('#names-zfa textarea').val();
    var lines = text.replace(/(?:(?:\r\n|\r|\n)\s*){2}/gm, "").split("\n");
    if (text) {
        var count = lines.length;
    } else {
        var count = 0;
    }

    var selectZfa = $('#count-zfa');
    selectZfa.data('container', $('#names-zfa'));

    selectZfa.on('change', processFields);

    $('#count-zfa').val(count).trigger("change");

    function processFields() {
        var $field = $(this);
        var amount = parseInt($field.val());
        var container = $field.data('container');
        var textarea = $('textarea', container);
        var textareaValues = textarea.val().split(/\n/);

        if (amount == 0) {
            container.hide();
        } else {
            $('.form-group', container).remove();

            var emailsArray = $('.email').map(function () {
                return this.value;
            }).get();

            var uniqArray = emailsArray.slice();

            $.unique(uniqArray);

            if (uniqArray.length != emailsArray.length) {
                event.preventDefault();
                alert("E-Mails sind nicht eindeutig für Teilnehmer");
            }

            for (var i = 0; i < amount; i++) {
                var input = $('<input class="name" type="text" placeholder="Teilnehmername">');
                var inputEmail = $('<input class="email" type="email" placeholder="Teilnehmer-Email">');
                var userType = $('<select class="type"><option value="">Bitte wählen</option><option value="ZFA">ZFA</option><option value="Behandler">Behandler</option></select>');

                if (textareaValues.length > i) {
                    var values = textareaValues[i].split("||");
                    input.val(values[0]);
                    userType.val(values[1]);
                    inputEmail.val(values[2]);
                }
                container.append($('<div class="form-group"/>').append((i + 1) + ". Teilnehmer").append(input).append(userType).append(inputEmail));
                container.on('change', refillTextarea);
            }
            container.show();
        }
        refillTextarea();

        function refillTextarea() {
            var names = [];
            $('.form-group', container).each(function () {

                if ($(".name", this).val() || $(".type", this).val() || $(".email", this).val()) {
                    names.push($(".name", this).val() + "||" + $(".type", this).val() + "||" + $(".email", this).val());
                }
            });

            textarea.val(names.join("\n"));
        }
    }
})
