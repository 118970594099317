$.fn.isFullyVisible = function() {
    var win = $(window);
    var parent = this.parents('.navigation-lvl2');

    var parentOffset = parent.offset().left + parent.outerWidth()
    var bounds = parentOffset + this.outerWidth();

    var viewport = {};
    viewport.left = win.scrollLeft();
    viewport.right = viewport.left + win.width();

    return (bounds <= viewport.right);
};