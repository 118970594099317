jQuery(function ($) {
    var categorySelector = $("#selectcategory");
    var citySelector = $("#selectcity");
    var speakerSelector = $("#selectspeaker");

    var category = ".all",
        city = ".all",
        speaker = ".all";
    if (categorySelector.val() != ".all") {
        category = categorySelector.val();
    }
    if (citySelector.val() != ".all") {
        city = citySelector.val();
    }
    if (speakerSelector.val() != ".all") {
        speaker = speakerSelector.val();
    }

    var containerEl = document.querySelector('.tx-px-seminars');
    if (!containerEl) {
        return;
    }
    var loadMoreEl = document.querySelector('#loadMore-rooms');
    var selectCity = document.querySelector('#selectcity');
    var selectSpeaker = document.querySelector('#selectspeaker');
    var selectCategory = document.querySelector('#selectcategory');
    var selectDate = document.querySelector('#datefilter');

    var currentLimit = 100;
    var incrementAmount = 1;

    var mixer = mixitup(containerEl, {

        multifilter: {enable: true},
        pagination: {limit: currentLimit, hidePageListIfSinglePage: true},
        load: {
            filter: category + city + speaker
        },
        animation: {
            duration: 300,
            effectsIn: 'fade translateY(100%)',
            effectsOut: false,
            easing: 'ease-in-out',
            queue: true,
            animateResizeContainer: false,
            animateResizeTargets: false
        },
        controls: {
            live: true,
            toggleLogic: 'and'
        }


    });


    function handleLoadMoreClick() {
        // On each click of the load more button, we increment
        // the current limit by a defined amount

        currentLimit += incrementAmount;

        mixer.paginate({limit: currentLimit});
    }


    selectCity.addEventListener('change', function () {
        var selector = selectCity.value;

        mixer.filter(selector);
    });

    selectSpeaker.addEventListener('change', function () {
        var selector2 = selectSpeaker.value;

        mixer.filter(selector2);
    });

    selectCategory.addEventListener('change', function () {
        var selector3 = selectCategory.value;

        mixer.filter(selector3);
    });

    selectDate.addEventListener('change', function () {
        var selector4 = selectDate.value;

        mixer.filter(selector4);
    });

    jQuery("#datepicker").on("changeDate", function (e) {
        var classTrigger = ".date" + e.date.getFullYear() + (e.date.getMonth() + 1) + e.date.getDate();
        $('#datefilter').val(classTrigger).prop('selected', true).trigger("change");
        mixer.filter(classTrigger);

    });
    jQuery("#resetdate").on("click", function (e) {
        mixer.filter(".all");
    });


});