$(function () {
    $("#videoberatung-699-text-4").prop('readonly', true);
    $(".subjectarea-field").each(function () {

        var field = $(this);
        $(".subjectarea-teaser").each(function () {
           var teaser = $(this);
            $(".subject-btn", teaser).on("click", function () {
                field.val($(".subject-title", teaser).text());
                $(".subjectarea-teaser").removeClass("active");
                teaser.toggleClass("active");
                var $option = $(".subject-title", teaser).text();
                $('#videoberatung-699-singleselect-3 option[value="'+$option+'"]').attr('selected', 'selected');
                //$("#videoberatung-699-singleselect-3").val($(".subject-title", teaser).text());
                $('html,body').unbind().animate({scrollTop: $("#scrollTo").offset().top-190},'slow');
            })
        });
    });
    $( window ).resize(function() {
        var form = $('#videoberatung-699');
        form.each(function() {
            $('.bootstrap').width(form.parent().width());
        });
    }).resize();
});
