jQuery(function () {
    var picker = jQuery("#datepicker");
    var datas = $.parseJSON(picker.attr("data-dates"));
    picker.datepicker(
        {
            format: 'mmddyyyy',
            language: 'de-DE',
            beforeShowDay: function (date) {
                var fullDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
                if (datas.indexOf(fullDate) !== -1) {
                    return {classes: 'highlight', tooltip: 'SEMINARE'};
                }
            }
        }
    );
});