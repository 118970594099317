$(function () {

    function updateTimeContainer() {
        $timeContainer.append($timeSelects);
        var containerId = '#date' + $dateSelect.val();
        console.log(containerId);
        var timeSelect = $(containerId);
        $selectedTimeContainer.append(timeSelect);
    }

    $timeContainer = $('#selectcontainer');
    var $selectedTimeContainer = $('#timeselect-container');
    var $timeSelects = $('[rel=time-select]');
    var $dateSelect = $('#videoconsult-date');

    updateTimeContainer();
    $dateSelect.on('change', updateTimeContainer);

});
