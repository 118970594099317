$(function () {
    $("[data-id='UserDelete']").click(function (event) {
        event.preventDefault();
        var host = window.location.host;
        var protocol = window.location.protocol;
        $.ajax({
            url: protocol + '//' + host + '/userMailDelete',
            type: "GET",
            data: $(".femanager_edit form").serialize(),
            success: function (response) {
                $("[data-id='UserDelete']").hide();
                $(".result").text(response.status);
            }
        });
    });
});