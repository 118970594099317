$(document).ready(function () {

var solrSuggestController = new SuggestController();
solrSuggestController.init();



});

function openSearch() {
    document.getElementById("searchOverlay").style.display = "block";
}

// Close the full screen search box
function closeSearch() {
    document.getElementById("searchOverlay").style.display = "none";
}