$(function () {
    $(document).on('status.cartWasChanged', function () {
        setTimeout(function () {
            console.log('updating');
            var $cart = $("#cart-preview .checkout-link");
            if ($cart.css('display') == 'block') {
                $cart.css('display', 'flex');
            }
            $('#linkToCart').show();
        }, 0);
    })
})
